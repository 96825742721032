import * as React from 'react';
import Button from '@mui/material/Button';
import { navigate } from 'gatsby';

import Layout from '../components/Layout/Layout';
import employImg from '../images/employ.svg';
import expertsImg from '../images/experts.svg';
import jobShecckerImg from '../images/job_sheeker.svg';
import * as classes from '../styles/index.module.scss';
import HowItWorkSection from '../components/HowItWorks/HowItWorkSection';
import WhyWeSection from '../components/WhyWe/WhyWeSection';
import FeedBackSection from '../components/FeedBackSection/FeedBackSection';
import SEO from '../components/Seo';
import Typography from '../components/Typography/Typography';

const featureData = [
  {
    img: employImg,
    title: 'Employers',
    description: 'Hire elite talent, expert-vetted and ready to excel',
    link: '/employer_landing', // Add the link for Employers
  },
  {
    img: expertsImg,
    title: 'Experts',
    description: 'Leverage expertise to connect talent and unlock a new revenue stream',
    link: '/experts_landing', // Add the link for Experts
  },
  {
    img: jobShecckerImg,
    title: 'Job seekers',
    description: 'Boost your search with expert endorsements',
    link: '/candidate_landing', // Add the link for Job Seekers
  },
];

const IndexPage = () => {
  const handleOnGetStartedClick = () => {
    navigate('/getstarted');
  };

  return (
    <Layout>
      <SEO title="ExpertsCircle | Break the Hiring Barrier!" />
      <div>
        <div className={classes.section1}>
          <div className={classes.heroSection}>
            <div className={classes.textContent}>
              <Typography variant="h1">
                <span className={classes.textContentLight}>Break the&nbsp;</span>
                <span className={classes.textContentBold}>Hiring Barrier!</span>
              </Typography>

              <Typography variant="p">
              Experts Circle redefines recruitment by allowing experts to source top candidates, providing employers with rigorously vetted and expert-endorsed talent across industries.              </Typography>

              <div className={classes.actions}>
                <Button
                  onClick={handleOnGetStartedClick}
                  className={classes.getStartedBut}
                  variant="contained"
                  color="secondary"
                >
                  Get Started
                </Button>
                <Button
                  onClick={() => navigate('/how_it_works')}
                  className={classes.learnMoreBut}
                  variant="outlined"
                  color="secondary"
                >
                  Learn More
                </Button>
              </div>
            </div>
            <div className={classes.yVideo}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/MCdfF-fiRG4?rel=0&controls=0"
                title="Introducing Experts Circle"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={classes.features}>
            {featureData.map(({ img, title, description,link }) => {
              return (
                <div onClick={() => navigate(link)} className={classes.featureCard} key={title}>
                  <div className={classes.featureImg}>
                    <img src={img} alt={title} />
                  </div>
                  <div className={classes.featureDetails}>
                    <Typography variant="h2">{title}</Typography>
                    <Typography variant="p">{description}</Typography>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <WhyWeSection />
        <div className={classes.lastSection}>
        <HowItWorkSection />
          <FeedBackSection />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
